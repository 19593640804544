const publicUrl = process.env.PUBLIC_URL;

export const portfolioItems = [
  {
    id: 1,
    thumbnail: `${publicUrl}/images/screenshots/nutriology/NutriologyLoginNew.webp`,
    companyName: "Nutriology",
    projectType: "Web Development, UI/UX Design",
    techUsed: [
      "React",
      "NodeJS",
      "Express",
      "MongoDB",
      "Cypress",
      "Google Cloud",
      "Invision",
    ],
    summaryText: `Nutriology is a meal and fitness planning app with plans designed by experts, but easily
        customizable by users. Currently in beta, Nutriology is an app with complex functionality behind the 
        scenes, yet remains simple and fun to use. The app was designed and developed by Xupe, with new features
        added on an ongoing basis.`,
    screenshot1: `${publicUrl}/images/screenshots/nutriology/NutriologyDashboardNew.webp`,
    screenshot2: `${publicUrl}/images/screenshots/nutriology/NutriologyMealPlan.webp`,
    screenshot3: `${publicUrl}/images/screenshots/nutriology/NutriologyShopping.webp`,
    screenshot4: `${publicUrl}/images/screenshots/nutriology/NutriologyPlanner.webp`,
    screenshot5: `${publicUrl}/images/screenshots/nutriology/NutriologyRecipeNew.webp`,
    textHead1: "Goal",
    text1: `Scope, design, and build from scratch an app that differentiates itself in a crowded
        market of other health and wellness apps.`,
    textHead2: "Challenge",
    text2: `Give users something tailored to them that helps that achieve their goals while 
        fitting into their existing routines and preferences.`,
    textHead3: "Solution",
    text3: `Blend the best of curation and customization. Plans are built and maintained by
        professional fitness trainers and dietitians, but users are able to modify meals and workouts,
        as well as create their own.`,
  },
  {
    id: 2,
    thumbnail: `${publicUrl}/images/screenshots/appliedgenomics/homepage.png`,
    companyName: "Applied Genomics",
    projectType: "Web Development",
    techUsed: ["PHP", "Symfony", "Docker", "Stripe Integration"],
    summaryText: `Applied Genomics wanted to have their ordering system streamlined 
        to be able to focus on DNA analysis and spend less time in managing the logistics. This project entailed
        creating a custom platform linked to a payment processor, accounting software and lab calendar.`,
    screenshot1: `${publicUrl}/images/screenshots/appliedgenomics/admin-order-timeline.png`,
    screenshot2: `${publicUrl}/images/screenshots/appliedgenomics/homepage.png`,
    // screenshot3: `${publicUrl}/images/screenshots/appliedgenomics/schedule.png`,
    screenshot4: `${publicUrl}/images/screenshots/appliedgenomics/admin-order-details.png`,
    screenshot5: `${publicUrl}/images/screenshots/appliedgenomics/admin-order.png`,
    textHead1: "Solution",
    text1: `Customers are able to purchase DNA kits, and schedule their analysis 
        based on the lab’s workload and the kits' constraints. Customers and orders are then registered on the custom portal with a timeline of what 
        needs to be done and when, with reminder when necessary. 
        The orders and customers are also logged into the accounting software for banking reconciliation.`,
    // textHead2: 'This is a Subheadline',
    // text2: `insert here`,
    // textHead3: 'This is a Subheadline',
    // text3: ``,
  },

  {
    id: 6,
    thumbnail: `${publicUrl}/images/screenshots/monitoring/grafana-node.png`,
    companyName: "Monitoring",
    projectType: "Monitoring and Dashboards",
    techUsed: ["Grafana", "Prometheus", "Kubernetes"],
    summaryText: `We provide hosting for all services from simple websites to 
        complex applications split into multiple microservices. We also provide proactive
        monitoring of various health metrics and statistics and fix potential issues before they
        arise.`,
    screenshot1: `${publicUrl}/images/screenshots/monitoring/grafana-node.png`,
    screenshot2: `${publicUrl}/images/screenshots/monitoring/service-status.png`,
    screenshot3: `${publicUrl}/images/screenshots/monitoring/real-time-alerting.png`,
    screenshot4: `${publicUrl}/images/screenshots/monitoring/capacity-planning.png`,
    screenshot5: `${publicUrl}/images/screenshots/monitoring/kubernetes-dashboard.png`,
    textHead1: "Alerting",
    text1: `If we were to experience issues with our services, we would get alerted 
        immediately and be able to fix the problem to keep you online.`,
    textHead2: "Slack Integration",
    text2: `Receive notifications in your company's Slack channel for crucial events
        and key metrics.`,
    textHead3: "Scalability",
    text3: `For all your business-critical applications, we can scale vertically and 
        horizontally across the world, to give you exceptional performance and peace of mind.`,
  },

  {
    id: 10,
    thumbnail: `${publicUrl}/images/screenshots/bionic/bionic-tools3.webp`,
    companyName: "Bionic Cyber",
    projectType: "Web Development, UI/UX Design",
    techUsed: ["NextJS, Django, Figma"],
    summaryText: `Bionic Cyber is a platform that gives security operations teams 
    an overview of their key metrics, taking in data from various platforms
    and compiling them in one place to streamline management of security teams and processes.`,
    screenshot1: `${publicUrl}/images/screenshots/bionic/bionic-process.webp`,
    screenshot2: `${publicUrl}/images/screenshots/bionic/bionic-people.webp`,
    screenshot3: `${publicUrl}/images/screenshots/bionic/bionic-insights.webp`,
    screenshot4: `${publicUrl}/images/screenshots/bionic/bionic-tools3.webp`,
    screenshot5: `${publicUrl}/images/screenshots/bionic/bionic-dashboard.webp`,
    textHead1: "The Goal",
    text1: `Create an intuitive and comprehensive platform for cybersecurity teams to
    review metrics on staff productivity, usage of existing tools, and data from external
    platforms, while identifying key areas of concern before they escalate to full-blown issues.`,
    textHead2: "The Challenge",
    text2: `Create designs from scratch based on project requirements and build a functional MVP
    with a small team under tight timeline restrictions.`,
    textHead3: "The Solution",
    text3: `Mobilizing a team of 1 Project Manager, 1 UI/UX Designer, and 2 Developers to design and 
    build a streamlined and intuitive MVP.`,
  },
  {
    id: 9,
    thumbnail: `${publicUrl}/images/screenshots/atai/atai-portal-top.webp`,
    companyName: "ATAI (UC Berkeley)",
    projectType: "Web Development, UI/UX Design",
    techUsed: [
      "NextJS, NodeJS, Express, MongoDB, Figma, RabbitMQ, WordPress, Google Cloud Platform",
    ],
    summaryText: `The Agricultural Technology Adoption Institute (ATAI) is nonprofit co-managed by the
      Center for Effective Global Action (UC Berkeley) and funded by the Bill and Melinda Gates Foundation.`,
    screenshot1: `${publicUrl}/images/screenshots/atai/atai-portal-top.webp`,
    screenshot2: `${publicUrl}/images/screenshots/atai/atai-variable-matrix.webp`,
    screenshot3: `${publicUrl}/images/screenshots/atai/atai-env-variable-matrix.webp`,
    screenshot4: `${publicUrl}/images/screenshots/atai/atai-project-filters.webp`,
    textHead1: "The Goal",
    text1: `Create a free and open portal for researchers to access and analyze harmonized data
      across a wide range of studies on transformative agricultural practices and poverty reduction in
      Sub-Saharan Africa and Southeast Asia.`,
    textHead2: "The Challenge",
    text2: `Design and build an easy-to-use portal that can quickly and efficiency compile and retrieve
      results based on a range of environmental and other variables selected by the user.`,
    textHead3: "The Solution",
    text3: `Provide a range of querying options with real-time results previews via a dynamic data cart, then
      generate and email the results files to researchers upon completion.`,
  },
  {
    id: 7,
    thumbnail: `${publicUrl}/images/screenshots/bridal/HomeThumbnail.png`,
    companyName: "Poppy Bridal",
    projectType: "UI/UX Design",
    techUsed: ["Figma"],
    summaryText: `Poppy Bridal, from Gravity Payments, allows bridal shops to manage their customers 
        and event details from a single place. Bridal shops can communicate directly with their customers
        and can choose from hundreds of dresses and stock items from the most popular designers
        and private labels.`,
    screenshot1: `${publicUrl}/images/screenshots/bridal/Customer.png`,
    screenshot2: `${publicUrl}/images/screenshots/bridal/Inventory2.png`,
    screenshot3: `${publicUrl}/images/screenshots/bridal/Calendar.png`,
    screenshot4: `${publicUrl}/images/screenshots/bridal/PointOfSale.png`,
    screenshot5: `${publicUrl}/images/screenshots/bridal/Home.png`,
    textHead1: "Runway Central",
    text1: `Poppy’s Runway Central is a virtual runway and showroom for bridal stores. The store was designed 
        to house thousands of dresses from different designers and to allow Bridal shops to browse and add to their
        inventory, including  specific sizes & customizations for each dress.`,
    textHead2: "Calendar",
    text2: `We designed and built the calendar from the ground up. 
        Poppy’s calendar allows Bridal Shops to manage their employee’s schedules, organize their day’s 
        work and book appointments with ease.  
        Bridal shops are also able to see their appointments by workday, type, and room.`,
    textHead3: "Dashboard",
    text3: `We designed the dashboard so that Bridal shops can keep everything in one place, 
        allowing them to run their bridal store effortlessly. Bridal stores can have an bird's eye view 
        of everything happening in their shops. The dashboard is made of customisable widgets 
        that bridal shops can toggle on and off depending on the store and its needs.`,
  },
  {
    id: 3,
    thumbnail: `${publicUrl}/images/screenshots/collegeye/Thumbnail.webp`,
    companyName: "CollegeEye",
    projectType: "UI/UX Design",
    techUsed: ["Figma"],
    summaryText: `CollegeEye is on a mission to redefine the college search process. 
        Moving beyond the usual tours and virtual visits, CollegeEye is built on a 
        unique matching system that connects prospective college students to trained and knowledgeable guides.`,
    screenshot1: `${publicUrl}/images/screenshots/collegeye/LandingPage.webp`,
    // screenshot2: `${publicUrl}/images/screenshots/collegeye/SearchResults.webp`,
    // screenshot3: `${publicUrl}/images/screenshots/heineken/dashboard.webp`,
    screenshot4: `${publicUrl}/images/screenshots/collegeye/SearchResults.webp`,
  },

  {
    id: 4,
    thumbnail: `${publicUrl}/images/screenshots/heineken/overview.png`,
    companyName: "Heineken",
    projectType: "UI/UX Design",
    techUsed: ["Figma"],
    summaryText: `Design project for Heineken's e-commerce portal, used by suppliers who order in very 
        large quantities. The Heineken portal allows suppliers to keep track of their stock, sales, news, 
        credit used/available and any other notifications the suppliers might find necessary.`,
    screenshot1: `${publicUrl}/images/screenshots/heineken/overview.png`,
    screenshot2: `${publicUrl}/images/screenshots/heineken/mobile.png`,
    screenshot3: `${publicUrl}/images/screenshots/heineken/dashboard.png`,
    screenshot4: `${publicUrl}/images/screenshots/heineken/product-search.png`,
    // textHead1: 'This is a Subheadline',
    // text1: 'Lorem ipsum dolor sit amet, '
  },
  // {
  //     id: 6,
  //     thumbnail: `${publicUrl}/images/screenshots/unknown/dashboard.png`,
  //     companyName: 'Getlion',
  //     projectType: 'UI/UX Design',
  //     techUsed: ['Figma'],
  //     summaryText: `insert here`,
  //     screenshot1: `${publicUrl}/images/screenshots/unknown/dashboard.png`,
  // },
  {
    id: 8,
    thumbnail: `${publicUrl}/images/screenshots/meetedgar/ME-thumbnail.webp`,
    companyName: "Meet Edgar",
    projectType: "UI/UX Design",
    techUsed: ["Invision"],
    summaryText: `MeetEdgar is a small bootstrapped start-up that built and 
        launched a popular social media scheduling tool, 
        used by numerous digital influencers and thought leaders.`,
    screenshot1: `${publicUrl}/images/screenshots/meetedgar/ME-homepage.webp`,
    screenshot2: `${publicUrl}/images/screenshots/meetedgar/ME-mobile.webp`,
    screenshot3: `${publicUrl}/images/screenshots/meetedgar/ME-features.webp`,
    screenshot4: `${publicUrl}/images/screenshots/meetedgar/ME-blog.webp`,
    screenshot5: `${publicUrl}/images/screenshots/meetedgar/ME-pricing.webp`,
    textHead1: "The Goal",
    text1: `While the company had created a one-of-a-kind product and 
        slowly gathered a loyal customer base, 
        MeetEdgar was eager to grow its following and revenue faster, 
        educate potential customers better, and be seen as the top product in its niche industry.`,
    textHead2: "The Challenge",
    text2: `MeetEdgar’s website and blog, the company’s primary product marketing resource 
        and its thought leadership hub, were doing a minimal jobs of educating and selling. 
        Both the website and blog looked and felt behind the times 
        and failed to reflect the playful tone of the MeetEdgar brand and company.`,
    textHead3: "The Solution",
    text3: `Reorganizing and restructuring the site’s existing content, 
        and bringing the brand to life with a fresh and playful new visual design that was 
        built to educate and convert. As a team, we started by redoing the main marketing site and 
        also went on to rebuild the company’s blog and numerous marketing landing pages.`,
  },
  // {
  //   id: 5,
  //   thumbnail: `${publicUrl}/images/screenshots/omeya/nurse-list.png`,
  //   companyName: "Omeya",
  //   projectType: "UI/UX Design",
  //   techUsed: ["Figma"],
  //   summaryText: `Omeya is a health startup that builds digital products for hospitals. 
  //   The Omeya Dashboard was created for a large private hospital
  //   that allowed the hospital to manage everything from one central place.`,
  //   screenshot1: `${publicUrl}/images/screenshots/omeya/nurse-list.png`,
  //   screenshot5: `${publicUrl}/images/screenshots/omeya/dashboard.png`,
  // },
  // {
  //     id: 8,
  //     thumbnail: `${publicUrl}/images/screenshots/online-traffic-school/BOTS-thumbnail.png`,
  //     companyName: 'Online Traffic School',
  //     projectType: 'UI/UX Design',
  //     techUsed: ['Invision'],
  //     summaryText: `In this project, we created a simple, user-friendly website for a traffic school service,
  //     to help them stand out in a crowded marketplace, full of price-sensitive competitors that lacked unique or
  //     remarkable branding and design aesthetic.`,
  //     screenshot1: `${publicUrl}/images/screenshots/online-traffic-school/01-BOTS-HP.png`,
  //     screenshot2: `${publicUrl}/images/screenshots/online-traffic-school/04-BOTS-HP-Mobile-Mock-up-02.png`,
  //     screenshot4: `${publicUrl}/images/screenshots/online-traffic-school/05-Checkout.png`,
  //     screenshot5: `${publicUrl}/images/screenshots/online-traffic-school/03-Pricing.png`,
  //     // textHead1: 'This is a Subheadline',
  //     // text1: `insert here`,
  // },
];
