import React from "react";
import styled from "styled-components";
import FlexContainer from "../elements/FlexContainer";
import { colors } from '../styleConstants';
import SiteNav from '../components/SiteNav';
import '../privacypolicy.css';

const PrivacyPolicy = () => {
  return (
    <Background src={`${process.env.PUBLIC_URL}/images/backgrounds/portfolio-background.webp`}
      >
      <SiteNav />
      <FlexContainer flexDirection="column" alignItems="center">
      <PolicyContainer flexDirection="column">
        <h1 className="c3" id="h.gjdgxs">
          <span>Privacy Policy</span>
        </h1>
        <p className="c3">
          <span className="c4">Last updated: June 29, 2022</span>
        </p>
        <p className="c3">
          <span className="c4">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </span>
        </p>
        <p className="c3">
          <span>
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of the{" "}
          </span>
          <span>
            <a
              className="c9"
              href="https://www.google.com/url?q=https://www.privacypolicies.com/privacy-policy-generator/&amp;sa=D&amp;source=editors&amp;ust=1659908134804249&amp;usg=AOvVaw1DSUHpbImBPKF5A0cZQUNm"
            >
              Privacy Policy Generator
            </a>
          </span>
          <span className="c4">.</span>
        </p>
        <p className="c8 title">
          <span className="c7">Interpretation and Definitions</span>
        </p>
        <h2 className="c6">
          <span className="c11">Interpretation</span>
        </h2>
        <p className="c3">
          <span className="c4">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </span>
        </p>
        <h2 className="c6">
          <span className="c11">Definitions</span>
        </h2>
        <p className="c3">
          <span className="c4">For the purposes of this Privacy Policy:</span>
        </p>
        <p className="c0">
          <span className="c1">Account</span>
          <span className="c4">
            &nbsp;means a unique account created for You to access our Service
            or parts of our Service.
          </span>
        </p>
        <p className="c0">
          <span className="c1">Company</span>
          <span className="c4">
            &nbsp;(referred to as either &quot;the Company&quot;,
            &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement)
            refers to XUPE &Otilde;U, Sepapaja 6, Tallin 15551, Estonia.
          </span>
        </p>
        <p className="c0">
          <span className="c1">Cookies</span>
          <span className="c4">
            &nbsp;are small files that are placed on Your computer, mobile
            device or any other device by a website, containing the details of
            Your browsing history on that website among its many uses.
          </span>
        </p>
        <p className="c0">
          <span className="c1">Country</span>
          <span className="c4">&nbsp;refers to: Estonia</span>
        </p>
        <p className="c0">
          <span className="c1">Device</span>
          <span className="c4">
            &nbsp;means any device that can access the Service such as a
            computer, a cellphone or a digital tablet.
          </span>
        </p>
        <p className="c0">
          <span className="c1">Personal Data</span>
          <span className="c4">
            &nbsp;is any information that relates to an identified or
            identifiable individual.
          </span>
        </p>
        <p className="c0">
          <span className="c1">Service</span>
          <span className="c4">&nbsp;refers to the Website.</span>
        </p>
        <p className="c0">
          <span className="c1">Service Provider</span>
          <span className="c4">
            &nbsp;means any natural or legal person who processes the data on
            behalf of the Company. It refers to third-party companies or
            individuals employed by the Company to facilitate the Service, to
            provide the Service on behalf of the Company, to perform services
            related to the Service or to assist the Company in analyzing how the
            Service is used.
          </span>
        </p>
        <p className="c0">
          <span className="c1">Usage Data</span>
          <span className="c4">
            &nbsp;refers to data collected automatically, either generated by
            the use of the Service or from the Service infrastructure itself
            (for example, the duration of a page visit).
          </span>
        </p>
        <p className="c0">
          <span className="c1">Website</span>
          <span className="c4">&nbsp;refers to Xupe, accessible from </span>
          <span className="c4">
            <a
              className="c9"
              href="https://www.google.com/url?q=http://www.xupe.org&amp;sa=D&amp;source=editors&amp;ust=1659908134805625&amp;usg=AOvVaw3lc5RNOs7Koj292WnJYkvT"
            >
              www.xupe.org
            </a>
          </span>
        </p>
        <p className="c3 c12">
          <span className="c1">You</span>
          <span className="c4">
            &nbsp;means the individual accessing or using the Service, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
          </span>
        </p>
        <p className="c8 title">
          <span className="c7">Collecting and Using Your Personal Data</span>
        </p>
        <h2 className="c6">
          <span className="c11">Types of Data Collected</span>
        </h2>
        <h3 className="c6">
          <span className="c5">Personal Data</span>
        </h3>
        <p className="c3">
          <span className="c4">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </span>
        </p>
        <p className="c0">
          <span className="c4">Email address</span>
        </p>
        <p className="c0">
          <span className="c4">First name and last name</span>
        </p>
        <p className="c3 c12">
          <span className="c4">Usage Data</span>
        </p>
        <h3 className="c6">
          <span className="c5">Usage Data</span>
        </h3>
        <p className="c3">
          <span className="c4">
            Usage Data is collected automatically when using the Service.
          </span>
        </p>
        <p className="c3">
          <span className="c4">
            Usage Data may include information such as Your Device&#39;s
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that You visit, the time and date
            of Your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </span>
        </p>
        <p className="c3">
          <span className="c4">
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </span>
        </p>
        <p className="c3">
          <span className="c4">
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </span>
        </p>
        <h3 className="c6">
          <span className="c5">Tracking Technologies and Cookies</span>
        </h3>
        <p className="c3">
          <span className="c4">
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
          </span>
        </p>
        <ul className="c13 lst-kix_list_1-0 start">
          <li className="c2 li-bullet-0">
            <span className="c1">Cookies or Browser Cookies.</span>
            <span className="c4">
              &nbsp;A cookie is a small file placed on Your Device. You can
              instruct Your browser to refuse all Cookies or to indicate when a
              Cookie is being sent. However, if You do not accept Cookies, You
              may not be able to use some parts of our Service. Unless you have
              adjusted Your browser setting so that it will refuse Cookies, our
              Service may use Cookies.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c1">Flash Cookies.</span>
            <span className="c4">
              &nbsp;Certain features of our Service may use local stored objects
              (or Flash Cookies) to collect and store information about Your
              preferences or Your activity on our Service. Flash Cookies are not
              managed by the same browser settings as those used for Browser
              Cookies. For more information on how You can delete Flash Cookies,
              please read &quot;Where can I change the settings for disabling,
              or deleting local shared objects?&quot; available at{" "}
            </span>
            <span className="c4">
              <a
                className="c9"
                href="https://www.google.com/url?q=https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html%23main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_&amp;sa=D&amp;source=editors&amp;ust=1659908134807188&amp;usg=AOvVaw2XMMGVB-9WxTbfzOKVO8kW"
              >
                https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
              </a>
            </span>
          </li>
          <li className="c3 c10 li-bullet-0">
            <span className="c1">Web Beacons.</span>
            <span className="c4">
              &nbsp;Certain sections of our Service and our emails may contain
              small electronic files known as web beacons (also referred to as
              clear gifs, pixel tags, and single-pixel gifs) that permit the
              Company, for example, to count users who have visited those pages
              or opened an email and for other related website statistics (for
              example, recording the popularity of a certain section and
              verifying system and server integrity).
            </span>
          </li>
        </ul>
        <p className="c3">
          <span>
            Cookies can be &quot;Persistent&quot; or &quot;Session&quot;
            Cookies. Persistent Cookies remain on Your personal computer or
            mobile device when You go offline, while Session Cookies are deleted
            as soon as You close Your web browser. Learn more about cookies on
            the{" "}
          </span>
          <span>
            <a
              className="c9"
              href="https://www.google.com/url?q=https://www.privacypolicies.com/blog/privacy-policy-template/%23Use_Of_Cookies_Log_Files_And_Tracking&amp;sa=D&amp;source=editors&amp;ust=1659908134807606&amp;usg=AOvVaw1NyUkRhLs6DvIr1FyALTlr"
            >
              Privacy Policies website
            </a>
          </span>
          <span className="c4">&nbsp;article.</span>
        </p>
        <p className="c3">
          <span className="c4">
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </span>
        </p>
        <p className="c0">
          <span className="c1">Necessary / Essential Cookies</span>
        </p>
        <p className="c0">
          <span className="c4">Type: Session Cookies</span>
        </p>
        <p className="c0">
          <span className="c4">Administered by: Us</span>
        </p>
        <p className="c0">
          <span className="c4">
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
          </span>
        </p>
        <p className="c0">
          <span className="c1">Cookies Policy / Notice Acceptance Cookies</span>
        </p>
        <p className="c0">
          <span className="c4">Type: Persistent Cookies</span>
        </p>
        <p className="c0">
          <span className="c4">Administered by: Us</span>
        </p>
        <p className="c0">
          <span className="c4">
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
          </span>
        </p>
        <p className="c0">
          <span className="c1">Functionality Cookies</span>
        </p>
        <p className="c0">
          <span className="c4">Type: Persistent Cookies</span>
        </p>
        <p className="c0">
          <span className="c4">Administered by: Us</span>
        </p>
        <p className="c3 c12">
          <span className="c4">
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
          </span>
        </p>
        <p className="c3">
          <span className="c4">
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </span>
        </p>
        <h2 className="c6">
          <span className="c11">Use of Your Personal Data</span>
        </h2>
        <p className="c3">
          <span className="c4">
            The Company may use Personal Data for the following purposes:
          </span>
        </p>
        <p className="c0">
          <span className="c1">To provide and maintain our Service</span>
          <span className="c4">
            , including to monitor the usage of our Service.
          </span>
        </p>
        <p className="c0">
          <span className="c1">To manage Your Account:</span>
          <span className="c4">
            &nbsp;to manage Your registration as a user of the Service. The
            Personal Data You provide can give You access to different
            functionalities of the Service that are available to You as a
            registered user.
          </span>
        </p>
        <p className="c0">
          <span className="c1">For the performance of a contract:</span>
          <span className="c4">
            &nbsp;the development, compliance and undertaking of the purchase
            contract for the products, items or services You have purchased or
            of any other contract with Us through the Service.
          </span>
        </p>
        <p className="c0">
          <span className="c1">To contact You:</span>
          <span className="c4">
            &nbsp;To contact You by email, telephone calls, SMS, or other
            equivalent forms of electronic communication, such as a mobile
            application&#39;s push notifications regarding updates or
            informative communications related to the functionalities, products
            or contracted services, including the security updates, when
            necessary or reasonable for their implementation.
          </span>
        </p>
        <p className="c0">
          <span className="c1">To provide You</span>
          <span className="c4">
            &nbsp;with news, special offers and general information about other
            goods, services and events which we offer that are similar to those
            that you have already purchased or enquired about unless You have
            opted not to receive such information.
          </span>
        </p>
        <p className="c0">
          <span className="c1">To manage Your requests:</span>
          <span className="c4">
            &nbsp;To attend and manage Your requests to Us.
          </span>
        </p>
        <p className="c0">
          <span className="c1">For business transfers:</span>
          <span className="c4">
            &nbsp;We may use Your information to evaluate or conduct a merger,
            divestiture, restructuring, reorganization, dissolution, or other
            sale or transfer of some or all of Our assets, whether as a going
            concern or as part of bankruptcy, liquidation, or similar
            proceeding, in which Personal Data held by Us about our Service
            users is among the assets transferred.
          </span>
        </p>
        <p className="c3 c12">
          <span className="c1">For other purposes</span>
          <span className="c4">
            : We may use Your information for other purposes, such as data
            analysis, identifying usage trends, determining the effectiveness of
            our promotional campaigns and to evaluate and improve our Service,
            products, services, marketing and your experience.
          </span>
        </p>
        <p className="c3">
          <span className="c4">
            We may share Your personal information in the following situations:
          </span>
        </p>
        <ul className="c13 lst-kix_list_1-0">
          <li className="c2 li-bullet-0">
            <span className="c1">With Service Providers:</span>
            <span className="c4">
              &nbsp;We may share Your personal information with Service
              Providers to monitor and analyze the use of our Service, to
              contact You.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c1">For business transfers:</span>
            <span className="c4">
              &nbsp;We may share or transfer Your personal information in
              connection with, or during negotiations of, any merger, sale of
              Company assets, financing, or acquisition of all or a portion of
              Our business to another company.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c1">With Affiliates:</span>
            <span className="c4">
              &nbsp;We may share Your information with Our affiliates, in which
              case we will require those affiliates to honor this Privacy
              Policy. Affiliates include Our parent company and any other
              subsidiaries, joint venture partners or other companies that We
              control or that are under common control with Us.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c1">With business partners:</span>
            <span className="c4">
              &nbsp;We may share Your information with Our business partners to
              offer You certain products, services or promotions.
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c1">With other users:</span>
            <span className="c4">
              &nbsp;when You share personal information or otherwise interact in
              the public areas with other users, such information may be viewed
              by all users and may be publicly distributed outside.
            </span>
          </li>
          <li className="c3 c10 li-bullet-0">
            <span className="c1">With Your consent</span>
            <span className="c4">
              : We may disclose Your personal information for any other purpose
              with Your consent.
            </span>
          </li>
        </ul>
        <h2 className="c6">
          <span className="c11">Retention of Your Personal Data</span>
        </h2>
        <p className="c3">
          <span className="c4">
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </span>
        </p>
        <p className="c3">
          <span className="c4">
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </span>
        </p>
        <h2 className="c6">
          <span className="c11">Transfer of Your Personal Data</span>
        </h2>
        <p className="c3">
          <span className="c4">
            Your information, including Personal Data, is processed at the
            Company&#39;s operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to &mdash; and maintained on &mdash;
            computers located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </span>
        </p>
        <p className="c3">
          <span className="c4">
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </span>
        </p>
        <p className="c3">
          <span className="c4">
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </span>
        </p>
        <h2 className="c6">
          <span className="c11">Disclosure of Your Personal Data</span>
        </h2>
        <h3 className="c6">
          <span className="c5">Business Transactions</span>
        </h3>
        <p className="c3">
          <span className="c4">
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </span>
        </p>
        <h3 className="c6">
          <span className="c5">Law enforcement</span>
        </h3>
        <p className="c3">
          <span className="c4">
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </span>
        </p>
        <h3 className="c6">
          <span className="c5">Other legal requirements</span>
        </h3>
        <p className="c3">
          <span className="c4">
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </span>
        </p>
        <ul className="c13 lst-kix_list_1-0">
          <li className="c2 li-bullet-0">
            <span className="c4">Comply with a legal obligation</span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c4">
              Protect and defend the rights or property of the Company
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c4">
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </span>
          </li>
          <li className="c2 li-bullet-0">
            <span className="c4">
              Protect the personal safety of Users of the Service or the public
            </span>
          </li>
          <li className="c3 c10 li-bullet-0">
            <span className="c4">Protect against legal liability</span>
          </li>
        </ul>
        <h2 className="c6">
          <span className="c11">Security of Your Personal Data</span>
        </h2>
        <p className="c3">
          <span className="c4">
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </span>
        </p>
        <p className="c8 title">
          <span className="c7">Children&#39;s Privacy</span>
        </p>
        <p className="c3">
          <span className="c4">
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </span>
        </p>
        <p className="c3">
          <span className="c4">
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent&#39;s consent before We collect and use that
            information.
          </span>
        </p>
        <p className="c8 title">
          <span className="c7">Links to Other Websites</span>
        </p>
        <p className="c3">
          <span className="c4">
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party&#39;s site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </span>
        </p>
        <p className="c3">
          <span className="c4">
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </span>
        </p>
        <p className="c8 title">
          <span className="c7">Changes to this Privacy Policy</span>
        </p>
        <p className="c3">
          <span className="c4">
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </span>
        </p>
        <p className="c3">
          <span className="c4">
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the
            &quot;Last updated&quot; date at the top of this Privacy Policy.
          </span>
        </p>
        <p className="c3">
          <span className="c4">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </span>
        </p>
        <p className="c8 title">
          <span className="c7">Contact Us</span>
        </p>
        <p className="c3">
          <span className="c4">
            If you have any questions about this Privacy Policy, You can contact
            us:
          </span>
        </p>
        <ul className="c13 lst-kix_list_1-0">
          <li className="c3 c10 li-bullet-0">
            <span className="c4">By visiting this page on our website: </span>
            <span className="c4">
              <a
                className="c9"
                href="https://www.google.com/url?q=https://www.xupe.org/contact&amp;sa=D&amp;source=editors&amp;ust=1659908134812209&amp;usg=AOvVaw0AFb05ndBYsm5f-fFMbQ6G"
              >
                https://www.xupe.org/contact
              </a>
            </span>
          </li>
        </ul>
      </PolicyContainer>
      </FlexContainer>
    </Background>
  );
};

const Background = styled.div`
  min-height: 100vh;
  background-image: ${props => `url(${props.src})`};
  background-color: ${colors.mainBlue};
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: content;
`

const PolicyContainer = styled(FlexContainer)`
  width: 80%;
  max-width: 80%;
  max-height: 80vh; 
  padding: 48px;
  background-color: #fff;
  overflow-y: scroll;
  margin-top: 24px;
`;

export default PrivacyPolicy;
