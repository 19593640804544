import React from 'react';
import styled from 'styled-components';
import FlexContainer from '../elements/FlexContainer';
import { colors } from '../styleConstants';

import SiteNav from '../components/SiteNav';
import Hero from '../components/Hero';
import SectionFeatures from '../components/SectionFeatures';
// import SectionProjects from '../components/SectionProjects';
import SectionTechnologies from '../components/SectionTechnologies';
import SectionTeam from '../components/SectionTeam';
import SectionTestimonials from '../components/SectionTestimonials';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

const LandingPage = (props) => {

  return (
    <Background
      desktop={`${process.env.PUBLIC_URL}/images/backgrounds/homepage-background.webp`}
      mobile={`${process.env.PUBLIC_URL}/images/backgrounds/mobile-background.webp`}>
      <SiteNav />
      <Hero />
      <Content>
        <SectionFeatures />
        <SectionTechnologies />
        <SectionTeam />
        {/* <SectionProjects /> */}
        <SectionTestimonials />
      </Content>
      <FooterContainer flexDirection="column">
        <Footer {...props} />
        <FlexContainer alignItems="center" justify="center" style={{ width: '100%', gap: '12px'}}>
          <Copyright>©2019-2023 Xupe. All rights reserved</Copyright>
          <PrivacyPolicyLink to={'/privacy-policy'}>Privacy Policy</PrivacyPolicyLink>
        </FlexContainer>
        
        <div>Icons made by <Attribution href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</Attribution> from <Attribution href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</Attribution></div>
      </FooterContainer>

    </Background>
  )
};

const Background = styled.div`
  background-image: ${props => `url(${props.desktop})`};
  background-color: #05082A;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: content;

  @media (max-width: 768px) {
    background-image: ${props => `url(${props.mobile})`};
  }
`

const Content = styled.div`
  > * {
    padding: 75px 150px;
  }

  @media (max-width: 768px) {
    > * {
      padding: 50px 70px;
    }
  }

  @media (max-width: 479px) {
    > * {
      padding: 50px 20px;
    }
  }

`

const FooterContainer = styled(FlexContainer)`
  ${'' /* position: absolute; */}
  bottom: 0;
  width: 100%;
`

const Attribution = styled.a`
  color: black;
  text-transform: none;
  &:hover {
    color: black;
  }
`
const Copyright = styled.p`
  color: #8a99c0;
  font-size: 9px;
  text-align: center;
  letter-spacing: 0.41px;
`

const PrivacyPolicyLink = styled(Link)`
  color: #8a99c0;
  font-size: 9px;
  text-align: center;
  letter-spacing: 0.41px;

  &:hover {
    color: ${colors.mainYellow};
    cursor: pointer;
  }
`;

export default LandingPage;
